.object-valuation__link {
  margin: var(--s-tiny) 0 0 0;
}

.object-valuation__source {
  margin-top: var(--s-tiny);
  margin-bottom: var(--s-small);
  text-align: center;
}

.object-valuation__link--centered {
  text-align: center;
}

.object-valuation__description {
  margin-bottom: var(--s-huge);
}