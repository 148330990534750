@import '@brainbay/components/src/_global-styles/media-queries.css';
@import '../modal-references/modal-references.variables.css';

.reference-map__header-container {
  position: absolute;
  z-index: var(--z-low);
  width: 100%;
  pointer-events: none;
}

@media (--m-huge) {
  /*
    We need to calculate the correct width of the header.
    The map will span over two columns which results in the header covering two columns instead of one.
    Using variables to calculate the correct width being consistent with the other reference modal headers.
  */
  .reference-map__header-container {
    --references-total-width: calc(
      var(--references-middle-width) + var(--references-left-width)
    );
    display: grid;
    grid-template-columns: 1fr calc(
        var(--references-middle-width) * 100% / var(--references-total-width)
      );
  }
}

@media (--m-huge) {
  .reference-map__header {
    grid-column: 2;
  }
}

.reference-map__map {
  margin: 0;
}

.reference-map__bar {
  display: flex;
  position: absolute;
  bottom: 0;
  align-items: center;
  z-index: var(--z-overlay);
  box-shadow: -5px 0px 5px 1px rgba(0, 0, 0, 0.25);
  background: var(--c-white);
  padding: var(--s-nano) var(--s-small);
  width: 100%;
}

.reference-map__text {
  margin: 0;
}

.reference-map__text--error {
  color: var(--c-secondary);
}

.reference-map__loader {
  transform: translateZ(0);
  animation: rotate 1s infinite linear;
  margin-right: var(--s-nano);
  border-top: 0.3rem solid var(--c-dim);
  border-right: 0.3rem solid var(--c-dim);
  border-bottom: 0.3rem solid var(--c-dim);
  border-left: 0.3rem solid var(--c-primary);
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}
