@import '@brainbay/components/src/_global-styles/media-queries.css';

.landing__feedback {
  position: fixed;
  right: 0;
  bottom: 10vh;
}

@media (min-height: 35rem) {
  .landing__feedback {
    bottom: 35vh;
  }
}

@media (min-height: 40rem) {
  .landing__feedback {
    bottom: 45vh;
  }
}

@media (--m-huge) {
  .landing__feedback {
    bottom: 25vh;
  }
}
