@import '@brainbay/components/src/_global-styles/media-queries.css';

.references__content {
  padding-top: var(--s-tiny);
  padding-right: var(--s-nano);
  padding-left: var(--s-nano);
}

@media (--m-medium) {
  .references__content {
    padding-right: var(--s-small);
    padding-left: var(--s-small);
  }
}

@media (--m-small) {
  .references__sort {
    display: flex;
    align-items: center;
  }
}

.references__sort p {
  margin: 0;
  margin-right: calc(var(--s-nano) * 0.5);
}

.references__text {
  margin-bottom: var(--s-nano);
}

@media (--m-medium) {
  .references__text {
    display: flex;
    justify-content: space-between;
  }
}

.references__paragraph {
  display: flex;
  align-items: center;
  margin-bottom: var(--s-nano);
}

.references__paragraph span:first-child {
  margin-right: calc(var(--s-nano) * 0.5);
}

.references__paragraph span:empty:after {
  width: 3ch;
}

.references__list-item {
  background: var(--c-white);
  padding: calc(var(--s-nano));
  padding-bottom: var(--s-nano);
}

@media (--m-medium) {
  .references__list-item {
    padding: var(--s-nano);
  }
}

@media (--m-big) {
  .references__list-item {
    display: grid;
    grid-template-columns: 85% 1fr;
    padding-right: 0;
  }
}

@media (--m-big) {
  .references__list-item > *:first-child {
    border-right: 1px solid var(--c-dim);
  }
}

.references__list-item .add-button {
  margin-top: var(--s-tiny);
}

@media (--m-big) {
  .references__list-item .add-button {
    margin-top: 0;
  }
}

.references__list-item--added {
  outline: 2px solid var(--c-primary);
}

.references__save-button {
  width: 100%;
}

.references__save-button {
  margin-top: var(--s-nano);
}

@media (--m-medium) {
  .button.references__save-button {
    display: none;
  }
}
