@import '@brainbay/components/src/_global-styles/media-queries.css';

:root {
  --references-left-width: 0;
  --references-middle-width: 75;
  --references-right-width: 25;
}

@media (--m-huge) {
  :root {
    --references-left-width: 10;
    --references-middle-width: 65;
  }
}
