@import '@brainbay/components/src/_global-styles/media-queries.css';

.search-result__button {
  display: flex;
  align-items: center;
  text-align: left;
  padding: 0;
}

.search-result__button .icon {
  margin-right: var(--s-pico);
}

.search-result__button svg {
  width: 2rem;
  height: 2rem;
  border: 1px solid var(--c-primary);
  border-radius: 50%;
}

.search-result__filter-description {
  margin-top: var(--s-nano);
  max-width: 50rem;
}

@media (--m-medium) {
  .search-result__filter-description {
    margin-bottom: 0;
    text-align: right;
  }
}