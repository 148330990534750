a.market-value-list__link {
  display: inline-block;
  position: relative;
  transition: transform var(--ease-out-fast), background var(--ease-out-medium);
  margin: 0;
  border-radius: 50%;
  padding: 0;
  width: 100%;
  height: 100%;
}

.market-value-list__link:hover {
  transform: scale(1.15);
  transition: transform var(--ease-out-fast), background var(--ease-out-medium);
}

.market-value-list__link:before {
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0);
  z-index: var(--z-negative);
  transition: transform var(--ease-out-fast), background var(--ease-out-medium);
  border-radius: 50%;
  background: var(--c-background);
  width: 100%;
  height: 100%;
  content: '';
}

.market-value-list__link:hover:before {
  transform: scale(1.15);
}

.market-value-list__link > * {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
}

.market-value-list__icon {
  color: var(--c-black);
}
