.object-history__divider {
  margin-top: var(--s-small);
  width: 100%;
  height: 1px;
  background-color: var(--c-dim);
  border: none;
}

.object-history__description {
  margin-top: var(--s-medium);
}