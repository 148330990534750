@import '@brainbay/components/src/_global-styles/media-queries.css';
@import './modal-references.variables.css';

.modal-references {
  position: relative;
  background: var(--c-background);
  height: 100%;
}

@media (--m-medium) {
  .modal-references {
    display: grid;
    grid-template-columns:
      calc(var(--references-left-width) * 1%)
      calc(var(--references-middle-width) * 1%)
      calc(var(--references-right-width) * 1%);
  }
}

.modal-references > * {
  overflow: auto;
}

.modal-references__grid-middle,
.modal-references__grid-left,
.modal-references__grid-right {
  margin: 0 auto;
  max-width: 130rem;
}

@supports (display: grid) {
  .modal-references__grid-middle,
  .modal-references__grid-left,
  .modal-references__grid-right {
    margin: initial;
    max-width: initial;
  }
}

.modal-references__grid-middle > *:last-child,
.modal-references__grid-left > *:last-child,
.modal-references__grid-right > *:last-child {
  margin-bottom: var(--s-medium);
}

.modal-references__grid-middle {
  grid-column: 2 / -2;
}

.modal-references__grid-left,
.modal-references__grid-right {
  display: none;
  box-shadow: -5px 0 5px 1px rgba(0 0 0 0.25);
  background: var(--c-white);
  padding: var(--s-medium) var(--s-tiny);
  padding-bottom: 0;
}

@media (--m-medium) {
  .modal-references__grid-left,
  .modal-references__grid-right {
    display: block;
    padding: var(--s-huge) var(--s-tiny);
    padding-bottom: 0;
  }
}

@media (--m-huge) {
  .modal-references__grid-left,
  .modal-references__grid-right {
    padding: var(--s-huge) var(--s-large);
    padding-bottom: 0;
  }
}

@media (--m-medium) {
  .modal-references__grid-left {
    grid-column: 1;
  }

  .modal-references__grid-right {
    grid-column: 3;
  }
}

.modal-references__grid-right {
  z-index: var(--z-low);
}

.modal-references__grid-middle--map {
  position: relative;
  padding: 0;
  height: 100%;
}

@media (--m-medium) {
  .modal-references__grid-middle--map {
    grid-column: 1 / 3;
    padding: 0;
  }
}

.modal-references__grid-middle--map > *:last-child {
  margin: 0;
}

.modal-references__title {
  margin-bottom: var(--s-nano);
  text-align: center;
}

.modal-references__selected-reference {
  position: relative;
}

.modal-references__delete-button {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  z-index: var(--z-low);
  transition: color var(--ease-in-fast);
  background: var(--c-white);
  padding: calc(var(--s-nano) * 0.5);
  color: var(--c-primary);
}

.modal-references__delete-button:not(:disabled):hover,
.modal-references__delete-button:focus {
  color: var(--c-secondary);
}

.modal-references__delete-button:disabled {
  color: var(--c-dim);
}

.modal-references__reset-button {
  width: 100%;
}

.modal-references__loading-reference-spinner {
  display: inline-flex;
  margin-right: calc(var(--s-nano) * -1);
}
