@import '@brainbay/components/src/_global-styles/media-queries.css';

.references-table {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.references-table__content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding-top: var(--s-tiny);
  padding-right: var(--s-nano);
  padding-left: var(--s-nano);
  height: auto;
  height: auto;
  overflow: hidden;
}

@media (--m-medium) {
  .references-table__content {
    padding-right: var(--s-small);
    padding-left: var(--s-small);
  }
}

.references-table__text {
  margin-bottom: var(--s-nano);
}

@media (--m-medium) {
  .references-table__text {
    display: flex;
    justify-content: space-between;
  }
}

.references-table__paragraph {
  display: flex;
  align-items: center;
  margin-bottom: var(--s-nano);
}

.references-table__paragraph span:first-child {
  margin-right: calc(var(--s-nano) * 0.5);
}

.references-table__paragraph span:empty:after {
  width: 3ch;
}

.references-table__list-item {
  background: var(--c-white);
  padding: calc(var(--s-nano) * 0.5);
  padding-bottom: var(--s-nano);
}

@media (--m-medium) {
  .references-table__list-item {
    padding: var(--s-nano);
  }
}

@media (--m-big) {
  .references-table__list-item {
    display: grid;
    grid-template-columns: 85% 1fr;
    padding: var(--s-nano);
    padding-right: 0;
  }
}

@media (--m-big) {
  .references-table__list-item > *:first-child {
    border-right: 1px solid var(--c-dim);
  }
}

.references-table__list-item .add-button {
  margin-top: var(--s-tiny);
}

@media (--m-big) {
  .references-table__list-item .add-button {
    margin-top: 0;
  }
}

.references-table__list-item--added {
  outline: 2px solid var(--c-primary);
}

.references-table__container {
  flex: 1;
  height: auto;
  overflow: scroll;
  overflow: auto;
}

.references-table__table {
  position: relative;
  border-collapse: collapse;
  background: var(--c-white);
}

.references-table__table th,
.references-table__table td {
  text-align: left;
  white-space: nowrap;
}

.references-table__table th {
  position: sticky;
  top: 0;
  vertical-align: bottom;
  z-index: var(--z-low);
  background: var(--c-white);
}

.references-table__table td {
  padding: var(--s-nano);
}

.references-table__header {
  border-bottom: 1px solid var(--c-dim);
}

.references-table__header-button {
  position: relative;
  transition: color var(--ease-in-fast);
  padding: var(--s-nano);
  padding-right: var(--s-small);
  height: 100%;
  text-align: left;
}

.references-table__header-button:hover,
.references-table__header-button:focus {
  color: var(--c-focus);
}

.references-table__header-arrow {
  position: absolute;
  top: 50%;
  right: var(--s-nano);
  transform: translateY(-50%);
}

.references-table__table tbody tr:nth-child(2n) {
  background-color: var(--c-primary-light);
}

.references-table__row--added {
  outline: 2px solid var(--c-primary);
}

.references-table__table td:first-child {
  position: relative;
  padding: var(--s-small);
}

.references-table__add-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
