@import '@brainbay/components/src/_global-styles/media-queries.css';

@media (--m-medium) {
  .object__notification,
  .object__notification + * {
    margin-top: calc(-1 * var(--s-tiny));
  }
}

.object__disclaimer {
  clear: both;
}

.object__tabbed-content-wrapper {
  max-width: 750px;
}
