@import '@brainbay/components/src/_global-styles/media-queries.css';

.references-search__search-bar {
  margin-top: var(--s-small);
}

.references-search__search-content {
  margin-bottom: var(--s-small);
  padding-top: var(--s-tiny);
  padding-right: var(--s-nano);
  padding-left: var(--s-nano);
}

@media (--m-medium) {
  .references-search__search-content {
    padding-right: var(--s-small);
    padding-left: var(--s-small);
  }
}

.references-search__search-content .search-result-list__item {
  position: relative;
}

@media (--m-big) {
  .references-search__list-item {
    display: flex;
    align-items: center;
  }
}

.references-search__list-item:after {
  position: absolute;
  top: 0;
  left: 0;
  border: solid transparent 2px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  content: '';
}

.references-search__list-item--error:after {
  border-color: var(--c-secondary);
}

.references-search__list-item--added:after {
  border-color: var(--c-primary);
}

@media (--m-big) {
  .references-search__list-item .search-result-item {
    border-right: 1px solid var(--c-dim);
  }
}

.references-search__add-button {
  margin-top: var(--s-nano);
}

@media (--m-big) {
  .references-search__add-button {
    margin-top: 0;
    margin-left: auto;
    width: 15%;
  }
}

.references-search__item-text--error {
  margin-top: var(--s-nano);
  margin-bottom: 0;
  color: var(--c-secondary);
}
